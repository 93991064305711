<template>
  <div class="flex flex-col bg-gray-10 p-8">
    <div class="flex w-full justify-between">
      <span class="text-md font-bold lg:px-4">
        {{ $t('account.menu.hello', { name: user.firstName }) }}
      </span>

      <button
        ref="el"
        class="lg:hidden"
        @click="isAccountMenuOpen = !isAccountMenuOpen"
      >
        <IconHamburger class="w-5" />
      </button>
    </div>
    <div
      role="menu"
      tabindex="-1"
      class="mt-6 flex flex-col gap-2 max-lg:hidden"
    >
      <NuxtLink
        v-for="entry in menuEntries"
        :key="entry.title"
        :to="entry.link"
        class="inline-flex w-full items-center gap-2 px-4 py-2 text-sm font-bold"
        active-class="bg-white"
        tabindex="-1"
      >
        <Component
          :is="'icon-' + entry.icon"
          v-if="entry.icon"
          class="mr-2 size-4"
        />
        {{ entry.title }}
        <IconAngleRight v-if="entry.arrow !== false" class="ml-auto w-4" />
      </NuxtLink>
    </div>
    <Transition name="slide-up">
      <div
        v-show="isAccountMenuOpen"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu-button"
        tabindex="-1"
      >
        <NuxtLink
          v-for="entry in menuEntries"
          :key="entry.title"
          :to="entry.link"
          class="block w-full py-2 text-sm"
          active-class="font-bold"
          tabindex="-1"
        >
          {{ entry.title }}
        </NuxtLink>
      </div>
    </Transition>
    <div class="mt-8">
      <AppButton
        class="w-full"
        icon="sign-out"
        variant="secondary"
        @click="logout"
      >
        {{ $t('account.menu.logout') }}
      </AppButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const { user, logout: swLogout } = useUser()
const { isProfessional } = useCustomer(user)
const { t } = useI18n()
const isAccountMenuOpen = ref(false)
const { getShopRoute } = useShopRouter()

const el = ref()

onClickOutside(el, () => {
  isAccountMenuOpen.value = false
})

const logout = async () => {
  await swLogout()
  await navigateTo(getShopRoute('/login'), { replace: true })
}

type Entry = {
  title: string
  link: string
  icon: string
  arrow?: boolean
}

const menuEntries = computed<Entry[]>(() => {
  const items: Entry[] = [
    {
      title: t('account.profile.title'),
      link: getShopRoute('/account/profile'),
      icon: 'user',
    },
    {
      title: t('account.address.title'),
      link: getShopRoute('/account/address'),
      icon: 'location',
    },
    {
      title: t('account.orders.title'),
      link: getShopRoute('/account/orders'),
      icon: 'shopping-bag',
    },
  ]

  if (isProfessional.value) {
    items.push({
      title: t('form.brandApplication.title'),
      link: getShopRoute('/account/brands'),
      icon: 'unlock',
    })

    items.push({
      title: t('account.downloads.title'),
      link: getShopRoute('/account/downloads'),
      icon: 'download',
    })
  }

  return items
})
</script>
